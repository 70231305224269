import React, { createContext } from 'react';

import { useGetUserProfileQuery } from '@/services/api.service';

import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage';

import { UserNotificationSettingsType, UserProfileType } from '@/types/domain';

type UserContextType = {
  user: UserProfileType | undefined;
  user_notification_settings: UserNotificationSettingsType | undefined;
  isLoading: boolean;
  isError: boolean;
};

const UserContext = createContext<UserContextType>({
  user: undefined,
  user_notification_settings: undefined,
  isLoading: false,
  isError: false,
});

const UserContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { value: jwt } = useLocalStorage<string>(StorageKeysEnum.jwt);

  const { data, isLoading, isError } = useGetUserProfileQuery(undefined, {
    skip: !jwt,
  });

  return (
    <UserContext.Provider
      value={{
        user: data?.user,
        user_notification_settings: data?.user_notification_settings,
        isLoading,
        isError,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

function useUserContext(): UserContextType {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserContextProvider');
  }
  return context;
}

export { UserContextProvider, useUserContext };
