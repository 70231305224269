import { GeyUserGeoPositionRes } from '@/types/domain';

export type FormatUserGeoPositionResType = {
  country: string;
  region: string;
};

export type UserGeoPositionType = {
  country: string;
  region: string;
  date: string;
};
export const formatUserGeoPositionRes = (
  data: GeyUserGeoPositionRes,
): FormatUserGeoPositionResType => {
  let country = '';
  let region = '';

  data.results.map((result) => {
    result.address_components.map((component) => {
      if (component.types.includes('country')) {
        country = component.short_name;
      } else if (component.types.includes('administrative_area_level_1')) {
        region = component.short_name;
      }
    });
  });

  return { country, region };
};

const bannedRegions = ['Luhansk', 'Donetsk'];
const bannedCountryCodes = [
  'CU', //Cuba
  'IR', //Iran
  'KP', //North Korea
  'SY', //Syria
  'RU', //Russia
  'BY', //Belarus
];

export const isBannedRegion = (region: string | undefined) =>
  bannedRegions.some((x) => region?.includes(x));

export const isBannedCountryByCode = (country_code: string) =>
  bannedCountryCodes.includes(country_code);
