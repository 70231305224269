import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(isToday);

export const dateToCurrentTZ = (date?: Dayjs | string) => dayjs(date).tz();
export const isTodayDate = (date: string) => dayjs(date).tz().isToday();

export const currentMonthNotEqualGiven = (isoDate: string) => {
  const givenDate = dayjs(isoDate);
  const currentDate = dayjs();
  return givenDate.month() !== currentDate.month();
};
