import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import {
  useLazyGetUserGeoPositionQuery,
  useUserGeoCoordinatesMutation,
} from '@/services/api.service';

import { RoutesEnum } from '@/enum/routes.enum';
import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage';

import { currentMonthNotEqualGiven } from '@/utils/date';
import {
  formatUserGeoPositionRes,
  isBannedCountryByCode,
  isBannedRegion,
  UserGeoPositionType,
} from '@/utils/geo';

const DetectGeoPosition: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { setItem: setLang } = useLocalStorage<string>(
    StorageKeysEnum.i18nextLng,
  );
  const { value: geoPosition, setItem: setGeoPosition } =
    useLocalStorage<UserGeoPositionType>(StorageKeysEnum.geoPosition);

  const [triggerCoordinates] = useUserGeoCoordinatesMutation();
  const [triggerGeoPosition] = useLazyGetUserGeoPositionQuery();

  useEffect(() => {
    const handleGeo = async () => {
      const coordinates = await triggerCoordinates().unwrap();
      const position = await triggerGeoPosition({
        lat: coordinates.location.lat,
        lng: coordinates.location.lng,
      }).unwrap();

      const data = formatUserGeoPositionRes(position);
      setGeoPosition({ ...data, date: new Date().toISOString() });
      if (
        isBannedCountryByCode(data?.country) ||
        isBannedRegion(data?.region)
      ) {
        navigate(RoutesEnum.ERROR);
      }
    };

    if (!geoPosition || currentMonthNotEqualGiven(geoPosition?.date)) {
      handleGeo();
    }
  }, [
    setGeoPosition,
    triggerGeoPosition,
    triggerCoordinates,
    geoPosition,
    navigate,
  ]);

  useEffect(() => {
    const lang = searchParams.get('lang');
    if (lang) {
      i18n.changeLanguage(lang, () => setLang(lang));
      searchParams.delete('lang');
      setSearchParams(searchParams);
    }
  }, [i18n, location.search, searchParams, setLang, setSearchParams]);

  //Scroll page to top during route change
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.pathname]);

  return null;
};

export default DetectGeoPosition;
