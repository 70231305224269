import React, { createContext, useContext, useMemo } from 'react';

import { useGetGameZoneTemplateQuery } from '@/services/api.service';

import { NHLSpinner } from '@/elements/Loaders/nhlSpinner';

import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage';

import { UserGeoPositionType } from '@/utils/geo';

import { GameZoneTemplateType } from '@/types/domain';

export type GameZoneTemplateContextType = {
  template: GameZoneTemplateType | [];
};

const GameZoneTemplateContext = createContext<GameZoneTemplateContextType>({
  template: [],
});

const GameZoneTemplateContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { value } = useLocalStorage<UserGeoPositionType>(
    StorageKeysEnum.geoPosition,
  );
  const { data, isFetching } = useGetGameZoneTemplateQuery(
    { country: value?.country },
    { skip: !value },
  );

  const template = useMemo(() => data?.sections || [], [data]);

  return (
    <GameZoneTemplateContext.Provider value={{ template }}>
      {children}
      {isFetching && <NHLSpinner />}
    </GameZoneTemplateContext.Provider>
  );
};

function useGameZoneTemplateContext(): GameZoneTemplateContextType {
  const context = useContext(GameZoneTemplateContext);
  if (context === undefined) {
    throw new Error(
      'useGameZoneTemplateContext must be used within a GameZoneTemplateContextProvider',
    );
  }
  return context;
}

export { GameZoneTemplateContextProvider, useGameZoneTemplateContext };
