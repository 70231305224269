import React, { useMemo, useState } from 'react';

import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Text,
  useMediaQuery,
  useTheme,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

import ChevronLeft from '@/assets/icons/ChevronLeft';
import { UserIcon } from '@/assets/icons/UserIcon';
import NHLLogoEN from '@/assets/images/NHL/GZLogoEn.svg';
import NHLLogoFR from '@/assets/images/NHL/GZLogoFr.svg';
import Burger from '@/assets/images/OlyBet/icon/burger.svg';

import ArrowButton from '@/components/Button/ArrowButton';

import { useSidebarContext } from '@/context/sidebar.context';
import { useUserContext } from '@/context/user.context';

import LangSwitcher from '@/elements/LangSwitcher';

import { MediaQueriesEnum } from '@/enum/mediaQueries.enum';
import { RoutesEnum } from '@/enum/routes.enum';
import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage';

import { enabledLanguages } from '@/utils/i18n';

const Header: React.FC = () => {
  const [isDesktop, isMobile] = useMediaQuery(
    [MediaQueriesEnum.isDesktop, MediaQueriesEnum.isMobile],
    { ssr: false },
  );
  const { onSidebarOpen } = useSidebarContext();
  const { value: lang } = useLocalStorage<string>(StorageKeysEnum.i18nextLng);
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();
  const { user } = useUserContext();

  const [logoLoaded, setLogoLoaded] = useState(false);

  const isLangSwitcherEnabled = useMemo(() => enabledLanguages.length > 1, []);

  const goToHome = () => {
    navigate(RoutesEnum.HOME);
  };

  const isHeaderShown = useMemo(
    () =>
      pathname !== RoutesEnum.SSOENTRY &&
      pathname !== RoutesEnum.SSOCALLBACK &&
      pathname !== RoutesEnum.ERROR,
    [pathname],
  );
  const isUpdateUserPage = useMemo(
    () => pathname === RoutesEnum.USERUPDATE,
    [pathname],
  );

  const isProfilePage = useMemo(
    () => pathname === RoutesEnum.PROFILE,
    [pathname],
  );

  return (
    <Box
      as='header'
      background={theme.colors.header.primaryBg}
      display={isHeaderShown ? 'block' : 'none'}
      h={{ base: '70px', lg: '96px' }}
      left='0'
      position='fixed'
      px='20px'
      py='0'
      top='0'
      w='100%'
      zIndex='2'
    >
      <Grid
        alignItems='center'
        h='100%'
        position='relative'
        templateColumns={
          isUpdateUserPage && isMobile
            ? 'minmax(160px, 1fr) 1fr'
            : '1fr minmax(160px, 1fr) 1fr'
        }
        w='100%'
      >
        {!isDesktop && !isUpdateUserPage && (
          <Image
            alt='menu-icon'
            cursor='pointer'
            onClick={onSidebarOpen}
            src={Burger}
          />
        )}

        {isDesktop && isProfilePage && (
          <ArrowButton
            justifySelf='start'
            leftIcon={<ChevronLeft />}
            onClick={goToHome}
          >
            Back
          </ArrowButton>
        )}

        <Button
          gridColumn={isUpdateUserPage && isMobile ? 1 : 2}
          justifySelf='center'
          onClick={goToHome}
          p='0'
          width='fit-content'
        >
          <Image
            alt=''
            display={logoLoaded ? 'block' : 'none'}
            onLoad={() => setLogoLoaded(true)}
            src={lang === 'fr' ? NHLLogoFR : NHLLogoEN}
            w={{ base: '160px', md: '220px', lg: '270px' }}
          />
        </Button>

        <Flex align='center' justify='flex-end'>
          {isDesktop && !isUpdateUserPage && user && (
            <Button
              alignItems='center'
              color={theme.colors.header.text}
              display='flex'
              fontSize='17px'
              fontWeight='700'
              justifyContent='flex-end'
              leftIcon={<UserIcon />}
              mr='20px'
              onClick={() => navigate(RoutesEnum.PROFILE)}
              px='5px'
              variant='unstyled'
            >
              <Text isTruncated>{user.username}</Text>
            </Button>
          )}
          {(isUpdateUserPage && isLangSwitcherEnabled) ||
          (isDesktop && isLangSwitcherEnabled) ? (
            <LangSwitcher />
          ) : null}
        </Flex>
      </Grid>
    </Box>
  );
};

export default Header;
