import { ComponentStyleConfig } from '@chakra-ui/react';

const Input: ComponentStyleConfig = {
  variants: {
    primary: {
      field: {
        width: '100%',
        borderTop: '0',
        borderLeft: '0',
        borderRight: '0',
        borderBottom: '3px solid',
        borderColor: 'input.border',
        paddingLeft: '0',
        fontSize: '16px',
        fontWeight: '600',
        lineHeight: '24px',
        color: 'input.text',
        borderRadius: '0',
        _placeholder: {
          color: 'input.placeholder',
        },
        _focus: {},
        _invalid: {
          borderColor: 'input.invalid',
          boxShadow: 'none',
        },
      },
    },
    readonly: {
      field: {
        backgroundColor: 'input.placeholder',
      },
    },
  },
};

export default Input;
