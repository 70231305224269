const colors = {
  absoluteDark: '#000000',
  defaultGray: '#666666',

  error: {
    text: '#FFFFFF',
  },

  favouriteTeamBanner: {
    bg: '#272727',
    text: '#FFFFFF',
  },

  toast: {
    success: '#009656',
    error: '#E22F2F',
    text: '#FFFFFF',
  },

  appBg: {
    primaryBg: '#272727',
    secondaryBg: '#181818',
  },
  header: {
    logo: '#FFFFFF',
    text: '#FFFFFF',
    primaryBg: 'linear-gradient(180deg, #020202 0%, #2A2929 100%)',
    secondaryBg: 'linear-gradient(180deg, #020202 0%, #2A2929 100%)',
  },
  cardLarge: {
    bg: '#424242',
    text: '#FFFFFF',
    gradient: 'linear-gradient(90deg, rgba(0, 0, 0, 0.28) 0%, #010101 84.14%)',
  },
  cardSmall: {
    bg: '#424242',
    text: '#FFFFFF',
    buttonBg: 'linear-gradient(180deg, #667EF8 0%, #3D58E2 100%)',
    buttonBgActive: 'linear-gradient(180deg, #FF8904 0%, #FF5F01 100%)',
    gradient: 'rgba(0, 0, 0, 0.9)',
    title: '#FFFFFF',
    description: '#C7C7C7',
  },
  tile: {
    bg: '#424242',
    text: '#C7C7C7',
    border: '#424242',
  },
  timer: {
    text: '#FFFFFF',
    image: '#AFAFAF',
    imageActive: '#FFFFFF',
  },
  section: {
    title: '#FFFFFF',
    leftImage: {
      title: '#FFFFFF',
      subtitle: '#FFFFFF',
      description: '#FFFFFF',
    },
  },
  carousel: {
    bullet: 'rgba(255, 255, 255, 0.5)',
    bulletActive: 'rgba(255, 255, 255, 0.9)',
  },
  langSwitcher: {
    bg: '#141414',
    border: '#141414',
    text: '#FFFFFF',
  },
  input: {
    border: '#D9D9D9',
    text: '#FFFFFF',
    placeholder: '#D9D9D9',
    invalid: '#E22F2F',
    cancel: '#D9D9D9',
    hint: '#D9D9D9',
    success: '#009656',
    eye: '#D9D9D9',
  },
  signup: {
    title: '#FFFFFF',
    text: '#FFFFFF',
    formBorder: '#e2e8f0',
    checkboxText: '#FFFFFF',
  },
  login: {
    title: '#FFFFFF',
    text: '#FFFFFF',
    formBorder: '#e2e8f0',
  },
  profile: {
    title: '#FFFFFF',
    text: '#FFFFFF',
    formBorder: '#e2e8f0',
  },
  resetPassword: {
    title: '#FFFFFF',
    text: '#FFFFFF',
    formBorder: '#e2e8f0',
  },
  forgotPassword: {
    title: '#FFFFFF',
    text: '#FFFFFF',
    formBorder: '#e2e8f0',
    success: '#009656',
  },
  unsubscribe: {
    title: '#FFFFFF',
    text: '#FFFFFF',
    formBorder: '#e2e8f0',
    iconButton: '#FFFFFF',
  },
  countrySelect: {
    bg: 'transparent',
    border: '#D9D9D9',
    text: '#FFFFFF',
    menuBg: '#181818',
    placeholder: '#D9D9D9',
    arrow: '#D9D9D9',
    invalid: '#E22F2F',
    menu: '#3B3B3B',
  },
  sidebar: {
    bg: '#181818',
    close: '#FFFFFF',
    logo: '#FFFFFF',
    text: '#FFFFFF',
  },
  userUpdate: {
    button: '#0183DA',
    buttonText: '#1B2027',
  },
  button: {
    defaultText: '#FFFFFF',
    primary: {
      bg: 'linear-gradient(180deg, #7BD46D 0%, #529248 100%)',
      border: '#7BD56D',
      disabledBg: 'linear-gradient(180deg, #7BD46D 0%, #529248 100%)',
      text: '#FFFFFF',
    },
    secondary: {
      bg: 'linear-gradient(180deg, #F73535 0%, #CF2A2A 100%)',
      border: '#EE3333',
      disabledBg: 'linear-gradient(180deg, #F73535 0%, #CF2A2A 100%)',
      text: '#FFFFFF',
    },
    tertiary: {
      bg: '#0183DA',
      disabledBg: '#0183DA',
      text: '#FFFFFF',
    },
  },

  optIn: {
    linkColor: '#0183DA',
    text: '#FFFFFF',
    checkboxText: '#FFFFFF',
  },
};

export default colors;
