import React, { useMemo } from 'react';

import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Image,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { UserIcon } from '@/assets/icons/UserIcon';
import NHLLogoEN from '@/assets/images/NHL/GZLogoEn.svg';
import NHLLogoFR from '@/assets/images/NHL/GZLogoFr.svg';

import { useSidebarContext } from '@/context/sidebar.context';
import { useUserContext } from '@/context/user.context';

import LangSwitcher from '@/elements/LangSwitcher';

import { RoutesEnum } from '@/enum/routes.enum';
import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage';

import { enabledLanguages } from '@/utils/i18n';

export const Sidebar: React.FC = () => {
  const { isSidebarOpen, onSidebarClose } = useSidebarContext();
  const theme = useTheme();
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { value: lang } = useLocalStorage<string>(StorageKeysEnum.i18nextLng);

  const goToHome = () => {
    onSidebarClose();
    navigate(RoutesEnum.HOME);
  };

  const isLangSwitcherEnabled = useMemo(() => enabledLanguages.length > 1, []);

  return (
    <Drawer isOpen={isSidebarOpen} onClose={onSidebarClose} placement='left'>
      <DrawerOverlay />
      <DrawerContent backgroundColor={theme.colors.sidebar.bg}>
        <DrawerCloseButton color={theme.colors.sidebar.close} />
        <DrawerHeader pt='50px'>
          <Button onClick={goToHome} variant='ghost'>
            <Image
              alt=''
              src={lang === 'fr' ? NHLLogoFR : NHLLogoEN}
              w='100%'
            />
          </Button>
        </DrawerHeader>

        <DrawerBody>
          {isLangSwitcherEnabled && (
            <Box mb='20px' mr='auto' pt='20px' w='fit-content'>
              <LangSwitcher />
            </Box>
          )}
          {user && (
            <Button
              alignItems='center'
              color={theme.colors.sidebar.text}
              display='flex'
              fontSize='17px'
              fontWeight='700'
              justifyContent='flex-start'
              leftIcon={<UserIcon />}
              maxW='100%'
              onClick={() => navigate(RoutesEnum.PROFILE)}
              px='5px'
              variant='unstyled'
              w='fit-content'
            >
              <Text noOfLines={1}>{user.username}</Text>
            </Button>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
