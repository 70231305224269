import React, { useCallback, useMemo, useState } from 'react';

import { Flex, useTheme } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Select, { ControlProps, components, StylesConfig } from 'react-select';

import GlobeIcon from '@/assets/icons/GlobeIcon';

import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage';

import {
  countryFlagByName,
  countryNameByCode,
  enabledLanguages,
} from '@/utils/i18n';

type LanguageItemType = {
  value: string;
  label: string;
  image: string;
};

const LangSwitcherOption = ({
  innerProps,
  label,
}: {
  innerProps: any;
  label: string;
}) => (
  <Flex align='center' p='10px 2px 10px 10px' {...innerProps}>
    {label}
  </Flex>
);

const LangSwitcherControl = ({
  children,
  ...props
}: ControlProps<any, false>) => {
  return (
    <components.Control {...props}>
      <GlobeIcon />
      {children}
    </components.Control>
  );
};

const LangSwitcher: React.FC = () => {
  const theme = useTheme();
  const { i18n } = useTranslation();
  const { value: lang, setItem: setLang } = useLocalStorage<string>(
    StorageKeysEnum.i18nextLng,
  );

  const languageOptions = useMemo<LanguageItemType[]>(
    () =>
      enabledLanguages.map((item: (typeof enabledLanguages)[number]) => ({
        value: item.toLowerCase(),
        label: countryNameByCode(item),
        image: countryFlagByName(item),
      })),
    [],
  );

  const defaultLanguage = useMemo(
    () =>
      languageOptions.find((x) => x.value === lang.toLowerCase()) ||
      languageOptions[0],
    [lang, languageOptions],
  );

  const [currentLang, setCurrentLang] = useState(defaultLanguage);

  const onLangChange = useCallback(
    (opt: LanguageItemType) => {
      setCurrentLang(opt);
      i18n.changeLanguage(opt.value, () => setLang(opt.value));
    },
    [i18n, setLang],
  );

  const customStyles = useMemo<StylesConfig<any, false, any>>(
    () => ({
      control: (provided) => ({
        ...provided,
        background: theme.colors.langSwitcher.bg,
        borderRadius: '20px',
        border: '0',
        borderColor: theme.colors.langSwitcher.border,
        width: 'auto',
        height: '30px',
        minHeight: '30px',
        padding: '3px 10px',
        outline: '0',
        boxShadow: 'none',
        cursor: 'pointer',
      }),
      input: (provided) => ({
        ...provided,
        paddingBottom: '0',
        paddingTop: '0',
      }),
      valueContainer: (props) => ({
        ...props,
        padding: '0 6px',

        div: {
          margin: '0',
        },
      }),
      indicatorsContainer: (props) => ({
        ...props,
        div: {
          padding: '0 3px 0 0',
        },
      }),
      singleValue: (props) => ({
        ...props,
        color: theme.colors.langSwitcher.text,
      }),
      option: (props) => ({
        ...props,
        cursor: 'pointer',
        color: theme.colors.langSwitcher.text,
      }),
      menuPortal: (provided) => ({
        ...provided,
        border: 'none',
        zIndex: 9999,
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 1,
        top: '0',
        marginTop: '0px',
        border: 'none',
        boxShadow: 'none',
        color: theme.colors.langSwitcher.text,
        background: theme.colors.langSwitcher.bg,
        cursor: 'pointer',
      }),
      indicatorSeparator: () => ({ display: 'none' }),
    }),
    [
      theme.colors.langSwitcher.bg,
      theme.colors.langSwitcher.border,
      theme.colors.langSwitcher.text,
    ],
  );

  return (
    <Select
      components={{
        Option: LangSwitcherOption,
        Control: LangSwitcherControl,
      }}
      defaultValue={currentLang}
      isSearchable={false}
      onChange={onLangChange}
      options={languageOptions}
      styles={customStyles}
      value={languageOptions.find((x) => x.value === currentLang.value)}
    />
  );
};

export default LangSwitcher;
