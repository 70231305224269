import React from 'react';

import { Box, Flex, keyframes } from '@chakra-ui/react';

import Loader from '@/assets/icons/loader.svg';
import Puck from '@/assets/icons/puck.svg';

const arrowAnimation = keyframes`
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}`;

export const NHLSpinner: React.FC = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        h: '100vh',
        w: '100%',
        background: 'absoluteDark',
        opacity: '0.7',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 996,
      }}
    >
      <Flex
        bg={'rgba(255,255,255, 0.7)'}
        borderRadius='20px'
        justify='center'
        p='20px'
      >
        <Box
          bg={`url(${Puck}) center center / 54px  no-repeat`}
          h='76px'
          w='76px'
        >
          <Box
            animation={`1.1s linear 0s infinite normal none running ${arrowAnimation}`}
            bg={`url(${Loader}) 0px center / 76px no-repeat`}
            h='76px'
            w='76px'
          />
        </Box>
      </Flex>
    </Box>
  );
};
