import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { ApiTagsEnum } from '@/enum/apiTags.enum';
import { GameCodeEnum } from '@/enum/gameCode.enum';
import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import {
  API_BASE_URL,
  CLIENT_CODE,
  GAMEZONE_TILES_URL,
  GEOLOCATION_KEY,
  TENANT_CODE,
} from '@/utils/constants';
import { getStorageItem } from '@/utils/localStorage';

import {
  GetGameZoneContestRes,
  GetGameZoneTemplateRes,
  GetUserGeoPositionParams,
  GeyUserGeoPositionRes,
  GetUserProfileRes,
  LoginUserBody,
  LoginWithTokenRes,
  ResetPasswordBody,
  ResetPasswordRes,
  SignInRes,
  SignUpRes,
  UpdateUserDataBody,
  UpdateUserDataSubscriptionBody,
  UpdateUserProfileBody,
  UpdateUserRes,
  UserGeoCoordinatesRes,
  UserRegisterBody,
  CreateNotificationSettingsBody,
  CreateNotificationSettingsRes,
  GetJWTWithSSOParams,
  GetJWTWithSSORes,
} from '@/types/domain';

export const api = createApi({
  reducerPath: 'mainApi',
  tagTypes: Object.values(ApiTagsEnum),
  baseQuery: fetchBaseQuery({
    baseUrl: '',
  }),
  endpoints: (builder) => ({
    getGameZoneTemplate: builder.query<
      GetGameZoneTemplateRes,
      { country: string }
    >({
      query: ({ country }) =>
        `${GAMEZONE_TILES_URL}/clients/${CLIENT_CODE}/gamezone-template?tenant=${TENANT_CODE}&country=${country}`,
      providesTags: [ApiTagsEnum.GET_GAME_ZONE_TEMPLATE],
    }),
    signIn: builder.mutation<SignInRes, LoginUserBody>({
      query: (body) => ({
        url: `${API_BASE_URL}/auth/login`,
        body,
        method: 'POST',
      }),
    }),
    signUp: builder.mutation<SignUpRes, UserRegisterBody>({
      query: (body) => ({
        url: `${API_BASE_URL}/auth/signup`,
        body,
        method: 'POST',
      }),
    }),
    generateTmpToken: builder.mutation<{ token: string }, void>({
      query: () => {
        const jwt = getStorageItem<string>(StorageKeysEnum.jwt);
        return {
          url: `${API_BASE_URL}/auth/tmp-token`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${jwt}`,
            'X-TENANT-ID': TENANT_CODE,
          },
        };
      },
    }),
    getRedirectLink: builder.query<
      { redirectUrl: string },
      { game_code: GameCodeEnum }
    >({
      query: ({ game_code }) => {
        const lang = getStorageItem<string>(StorageKeysEnum.i18nextLng);
        return {
          url: `${API_BASE_URL}/sso/${CLIENT_CODE}?appCode=${game_code}&lang=${lang}`,
        };
      },
    }),
    loginWithToken: builder.mutation<LoginWithTokenRes, { token: string }>({
      query: (body) => ({
        url: `${API_BASE_URL}/auth/login-with-token`,
        method: 'POST',
        body,
      }),
    }),
    requestResetLink: builder.mutation<{ success: boolean }, { email: string }>(
      {
        query: (body) => ({
          url: `${API_BASE_URL}/auth/forgot-password`,
          method: 'POST',
          body,
        }),
      },
    ),
    resetPassword: builder.mutation<ResetPasswordRes, ResetPasswordBody>({
      query: (body) => ({
        url: `${API_BASE_URL}/auth/renew-password`,
        method: 'POST',
        body,
      }),
    }),
    updateUserDataSubscription: builder.mutation<
      { success: boolean },
      UpdateUserDataSubscriptionBody
    >({
      query: (body) => ({
        url: `${API_BASE_URL}/user/notification-preferences`,
        method: 'PUT',
        body,
      }),
    }),
    userGeoCoordinates: builder.mutation<UserGeoCoordinatesRes, void>({
      query: () => ({
        url: `https://www.googleapis.com/geolocation/v1/geolocate?key=${GEOLOCATION_KEY}`,
        method: 'POST',
      }),
    }),
    getUserGeoPosition: builder.query<
      GeyUserGeoPositionRes,
      GetUserGeoPositionParams
    >({
      query: ({ lat, lng }) =>
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&sensor=false&key=${GEOLOCATION_KEY}&language=en`,
      providesTags: [ApiTagsEnum.GET_USER_GEO_POSITION],
    }),
    getUserProfile: builder.query<GetUserProfileRes, void>({
      query: () => {
        const jwt = getStorageItem<string>(StorageKeysEnum.jwt);
        return {
          url: `${API_BASE_URL}/user/me`,
          headers: {
            Authorization: `Bearer ${jwt}`,
            'X-TENANT-ID': TENANT_CODE,
          },
        };
      },
      providesTags: [ApiTagsEnum.GET_USER_PROFILE],
    }),

    updateUserProfile: builder.mutation<
      { success: boolean },
      UpdateUserProfileBody
    >({
      query: (body) => {
        const jwt = getStorageItem<string>(StorageKeysEnum.jwt);
        return {
          url: `${API_BASE_URL}/user`,
          method: 'PUT',
          body,
          headers: {
            Authorization: `Bearer ${jwt}`,
            'X-TENANT-ID': TENANT_CODE,
          },
        };
      },
      invalidatesTags: [ApiTagsEnum.GET_USER_PROFILE],
    }),
    getGameZoneContest: builder.query<
      GetGameZoneContestRes,
      { url: string; country: string }
    >({
      query: ({ url, country }) => ({ url: `${url}&country=${country}` }),
    }),
    updateUserData: builder.mutation<UpdateUserRes, UpdateUserDataBody>({
      query: ({ code, ...body }) => ({
        url: `${API_BASE_URL}/user/${code}`,
        method: 'POST',
        body,
      }),
    }),
    createNotificationSettings: builder.mutation<
      CreateNotificationSettingsRes,
      CreateNotificationSettingsBody
    >({
      query: ({ code, ...body }) => ({
        url: `${API_BASE_URL}/user/opt-in/${code}`,
        method: 'POST',
        body,
      }),
    }),
    getJWTWithSSO: builder.query<GetJWTWithSSORes, GetJWTWithSSOParams>({
      query: ({ code, state }) => ({
        url: `${API_BASE_URL}/sso/callback?code=${code}&state=${state}`,
      }),
    }),
  }),
});

export const {
  useGetGameZoneTemplateQuery,
  useSignInMutation,
  useSignUpMutation,
  useGenerateTmpTokenMutation,
  useLoginWithTokenMutation,
  useRequestResetLinkMutation,
  useResetPasswordMutation,
  useUpdateUserDataSubscriptionMutation,
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
  useLazyGetGameZoneContestQuery,
  useLazyGetUserGeoPositionQuery,
  useLazyGetRedirectLinkQuery,
  useUpdateUserDataMutation,
  useLazyGetUserProfileQuery,
  useUserGeoCoordinatesMutation,
  useCreateNotificationSettingsMutation,
  useGetJWTWithSSOQuery,
} = api;
