import EST from '@/assets/icons/countries/EST.svg';
import FRN from '@/assets/icons/countries/FRN.png';
import LAT from '@/assets/icons/countries/LAT.svg';
import RUS from '@/assets/icons/countries/RUS.png';
import SVK from '@/assets/icons/countries/SVK.svg';
import USA from '@/assets/icons/countries/USA.svg';

import { ENABLED_LANGUAGES } from '@/utils/constants';

export const enabledLanguages = ENABLED_LANGUAGES?.split(',') || ['en'];

export const countryFlagByName = (name: (typeof enabledLanguages)[number]) => {
  switch (name.toLowerCase()) {
    case 'ee':
      return EST;
    case 'lv':
      return LAT;
    case 'sk':
      return SVK;
    case 'ru':
      return RUS;
    case 'fr':
      return FRN;
    case 'en':
    default:
      return USA;
  }
};

export const countryNameByCode = (name: (typeof enabledLanguages)[number]) => {
  switch (name.toLowerCase()) {
    case 'ee':
      return 'Eesti';
    case 'lv':
      return 'Latviešu';
    case 'sk':
      return 'Slovenský';
    case 'ru':
      return 'Russian';
    case 'fr':
      return 'Français';
    case 'en':
    default:
      return 'English';
  }
};

export const countryCodeByName = (name: string) => {
  switch (name) {
    case 'Eesti':
      return 'ee';
    case 'Latviešu':
      return 'lv';
    case 'Slovenský':
      return 'sk';
    case 'Russian':
      return 'ru';
    case 'Français':
      return 'fr';
    case 'English':
    default:
      return 'en';
  }
};
